//sticky nav
const bodyEl = document.querySelector('BODY');
const headerEl = document.querySelector('header.header');
const sentinalEl = document.querySelector('.snt');

const handler = (entries) => {
    if (!entries[0].isIntersecting) {
        headerEl.classList.add('is-sticky');
        bodyEl.classList.add('is-sticky');
    } else {
        var ww = document.querySelector('html').clientWidth;
        //adjust the mobileNavBreakPoint value to your mobile nav breakpoint, e.g. if it is 1120, put 1120
        mobileNavBreakPoint = 1120;
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	    	//silence
	    }
	    else{
		    if(document.querySelector('html').scrollHeight > document.querySelector('html').clientHeight){
		    	mobileNavBreakPoint = mobileNavBreakPoint - 15;
		    }
	    }
        if(ww > mobileNavBreakPoint){
            headerEl.classList.remove('is-sticky');
            bodyEl.classList.remove('is-sticky');
        }
    }
}

const observer = new window.IntersectionObserver(handler);
observer.observe(sentinalEl);


//is-sticky class to header and body if #site-navigation is not in viewport
if(!!window.pageYOffset){

    function isElementInViewport (el) {

        // Special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }

        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    function onVisibilityChange(el, callback) {
        var old_visible;
        return function () {
            var visible = isElementInViewport(el);
            if (visible != old_visible) {
                old_visible = visible;
                if (typeof callback == 'function') {
                    callback();
                }
            }
        }
    }

    var onVisibilityChangeHandler = onVisibilityChange(document.querySelector('.header-nav'), function() {
        document.querySelector('BODY').classList.add('is-sticky');
        document.querySelector('header.header').classList.add('is-sticky');
    });


    if (window.addEventListener) {
        addEventListener('load', onVisibilityChangeHandler, false);
    } else if (window.attachEvent)  {
        attachEvent('onload', onVisibilityChangeHandler);
    }

    // onVisibilityChange(document.querySelector('#site-navigation'));

}


//is-sticky class to header and body if window < mobileNavBreakPoint

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

var checkWindowWidth = debounce(function() {
    const bodyEl = document.querySelector('BODY');
    const headerEl = document.querySelector('header.header');
    // var ww = window.innerWidth;
    var ww = document.querySelector('html').clientWidth;
    //adjust the mobileNavBreakPoint value to your mobile nav breakpoint plus 1, e.g. if it is 1120, put 1121
    mobileNavBreakPoint = 1121;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    	//silence
    }
    else{
	    if(document.querySelector('html').scrollHeight > document.querySelector('html').clientHeight){
	    	mobileNavBreakPoint = mobileNavBreakPoint - 15;
	    }
	}
    if(ww < mobileNavBreakPoint){
        headerEl.classList.add('is-sticky');
        bodyEl.classList.add('is-sticky');
    }
    else{
        if(!window.pageYOffset){
            headerEl.classList.remove('is-sticky');
            bodyEl.classList.remove('is-sticky');
        }
    }
}, 200);

window.addEventListener('resize', checkWindowWidth);
checkWindowWidth();