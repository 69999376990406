(function($) {

	// console.log('backtotop is ready');

	// Back to top link
    $('.backtotop').click(function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });


})(jQuery);