(function($) {
	let shopSlideCount = 1;
	 //console.log('slick is ready');
	shopSlider();

	$('.shop_slider_wrapper').slick({
		row: 0,
		infinite: true,
		slidesToShow: 1,
		fade: true,
		dots: true,
		adaptiveHeight: true
	});



	$('.slider .wc-block-grid__products').slick({
		row: 0,
		infinite: true,
		slidesToShow: shopSlideCount,
		fade: false,
		dots: true,
		adaptiveHeight: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
		responsive: [
			{
			  breakpoint: 1300,
			  settings: {
				slidesToShow: 3,
			  }
			},
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2 ,
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});

	function shopSlider(){
		const slider = $('.slider.wc-block-grid');
		if (slider.hasClass("has-1-columns")) {
				shopSlideCount = 1
			}else if(slider.hasClass("has-2-columns")){
				shopSlideCount = 2
			}else if(slider.hasClass("has-3-columns")){
				shopSlideCount = 3
			}else if(slider.hasClass("has-4-columns")){
				shopSlideCount = 4
			}else if(slider.hasClass("has-5-columns")){
				shopSlideCount = 5
			}else{
				shopSlideCount = 6
			}
			
	}



})(jQuery);