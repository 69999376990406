(function($) {

	$(window).on('load', function() {

        //Content Element - Tabs
        jQuery('DIV.ce-tabs').each(function(index){
            var tabs_count = index;
            jQuery(this).attr('data-ce-tabs-index',tabs_count);

            jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] .tab_wrapper .ce-tab-title').each(function(index, el) {
            	var tabTitle = jQuery(this).text();
            	jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] ul.tabs').append('<li><a href="javascript:;">'+tabTitle+'</a></li>');
            	jQuery(this).remove();
            });

            jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] ul.tabs LI:first-child').remove();
            jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] ul.tabs LI:first-child > A').addClass('active_tab');

            jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] ul.tabs LI').click(function(){
                var currentTabIndex = jQuery(this).index();
                console.log(currentTabIndex);
                jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] ul.tabs LI > A').removeClass('active_tab');
                jQuery(this).find('A').addClass('active_tab');
                jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] .tab_wrapper .tab').hide();
                jQuery('.ce-tabs[data-ce-tabs-index="'+tabs_count+'"] .tab_wrapper .tab:nth-child('+(currentTabIndex+1)+')').show();
            })
        })

         //Tabs
         jQuery('DIV.ui-tabs').each(function(index){
            var count = index;
            jQuery(this).attr('data-ui-tabs-index',count);
            jQuery('.ui-tabs[data-ui-tabs-index="'+count+'"] .tab-nav LI').click(function(){
                var curLInav = jQuery(this).index();
                jQuery('.ui-tabs[data-ui-tabs-index="'+count+'"] li').removeClass('act');
                jQuery('.ui-tabs[data-ui-tabs-index="'+count+'"] ul.tab-content li:nth-child('+(curLInav+1)+')').addClass('act');
                jQuery(this).toggleClass('act');
            })
        })

	});//window load


})(jQuery);