(function($) {

	$(window).on('load', function() {

		//Content Element - Accordion
		jQuery('DIV.ce-accordion').each(function(index){
			var count = index;
			jQuery(this).attr('data-ce-accordion-index',count);

			var uniqueAccordionID = jQuery(this).attr('data-ce-accordion-index');

			var entry = jQuery('.ce-accordion[data-ce-accordion-index="'+count+'"] li.entry > .accordion-headline');

			var entryActHeight = jQuery('DIV.ce-accordion .entry.act .accordion-inner-wrap').height();


			jQuery('DIV.ce-accordion .entry.act .accordion-content-wrap').css('padding-bottom', entryActHeight + 'px');

			var accRdCheck = debounce(
				function(){
				jQuery('.entry').removeClass('act');
				jQuery('.entry .accordion-content-wrap').attr('style','');
			}, 200);
			window.addEventListener('resize', accRdCheck);
			entry.click(function(){
				var wrapper = jQuery(this).parent().find('.accordion-content-wrap');
				var innerWrapper = wrapper.find('.accordion-inner-wrap').height();
				// remove class from old accordion
				jQuery('.ce-accordion[data-ce-accordion-index='+uniqueAccordionID+'] .entry').not(jQuery(this).parent()).removeClass('act');
				// add class for active accordion
				jQuery(this).parent().toggleClass('act');
				// remove padding from old accordion
				jQuery('.ce-accordion[data-ce-accordion-index="'+count+'"] li.entry').find('.accordion-content-wrap').css('padding', '0px');
				// add padding to active accordion
				if(wrapper.css('padding-bottom') == 0 + 'px') {
					wrapper.css('padding-bottom', innerWrapper + 'px');
				}
				else {
					wrapper.css('padding-bottom', '0px');
				}
			});
		});

	});//window load


})(jQuery);