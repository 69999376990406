function resizeActive() {
    const classes = document.body.classList;
    const navbarToggler = document.querySelector('.header .navbar-toggler');
    const breakpoint = 1120;
    const mobileMenuClass = 'nav-active';
    let timer = 0;

    window.addEventListener('resize', () => {
        if (window.innerWidth >= breakpoint) {
            classes.remove(mobileMenuClass);
            navbarToggler.classList.remove('active');
            return;
        }
        if (timer) {
            clearTimeout(timer);
            timer = null;
        } else {
            classes.add('stop-transitions');
        }
            timer = setTimeout(() => {
            classes.remove('stop-transitions');
            timer = null;
        }, 10);
    });
}

(function($) {

    $(document).ready(function() {

        resizeActive();

    }); //doc ready

})(jQuery);