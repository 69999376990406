function adjustWpBlockSpacer_debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

var adjustWpBlockSpacer = adjustWpBlockSpacer_debounce(function() {
    var ww = document.querySelector('html').clientWidth;
    //adjust the pageIsNotBoxedAnymoreAt value to the value your page is not boxed anymore
    pageIsNotBoxedAnymoreAt = 1600;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    	//silence
    }
    else{
	    if(document.querySelector('html').scrollHeight > document.querySelector('html').clientHeight){
	    	pageIsNotBoxedAnymoreAt = pageIsNotBoxedAnymoreAt - 15;
	    }
	}
    if(ww < pageIsNotBoxedAnymoreAt){
        jQuery('.wp-block-spacer').each(function(){
        	if(jQuery(this).attr('data-org-h') == undefined)
        		{
					var org_height = jQuery(this).height();
					jQuery(this).attr('data-org-h',org_height);
				}
				else{
					var org_height = jQuery(this).attr('data-org-h');
				}
			jQuery(this).css('height',org_height/pageIsNotBoxedAnymoreAt*100+'vw');
		});
    }
    else{
        jQuery('.wp-block-spacer').each(function(){
    		var org_height = jQuery(this).height();
        	if(jQuery(this).attr('data-org-h') == undefined)
        	{
				jQuery(this).attr('data-org-h',org_height);
        	}
    		else{
        		jQuery(this).css('height',jQuery(this).attr('data-org-h')+'px');
        	}
		});
    }
}, 200);

window.addEventListener('resize', adjustWpBlockSpacer);
adjustWpBlockSpacer();