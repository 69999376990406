// (function($) {

//  $('.mm').click(function(){
//      $(this).toggleClass('open');
//      $('nav.nav').toggleClass('open');
//  });

//  if ( $('.mm').is(':visible') == true ){
//      $('.showSub').on('click', function(){
//          $(this).parent('li').children('.subNav').slideToggle(200);
//          $(this).toggleClass('open');
//          $(this).parent('li').toggleClass('open');
//      });
//  };

// })(jQuery);


/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function() {
    const siteNavigation = document.querySelector( '.header-nav-side' );
    const siteBody = document.getElementsByTagName("BODY")[0];
    const activeClass = 'nav-active';

    // Return early if the navigation don't exist.
    if ( ! siteNavigation ) {
        return;
    }

    const button = siteNavigation.querySelector( '.header__nav-toggler' );

    // Return early if the button don't exist.
    if ( 'undefined' === typeof button ) {
        return;
    }

    const menu = siteNavigation.getElementsByTagName( 'ul' )[ 0 ];

    // Hide menu toggle button if menu is empty and return early.
    if ( 'undefined' === typeof menu ) {
        button.style.display = 'none';
        return;
    }

    if ( ! menu.classList.contains( 'nav-menu' ) ) {
        menu.classList.add( 'nav-menu' );
    }

    // Toggle the .toggled class and the aria-expanded value each time the button is clicked.
    button.addEventListener( 'click', function() {
        siteNavigation.classList.toggle( 'toggled' );
        siteBody.classList.toggle( activeClass );

        if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
            button.setAttribute( 'aria-expanded', 'false' );
        } else {
            button.setAttribute( 'aria-expanded', 'true' );
        }
    } );

    // Remove the .toggled class and set aria-expanded to false when the user clicks outside the navigation.
    document.addEventListener( 'click', function( event ) {
        const isClickInside = siteNavigation.contains( event.target );

        if ( ! isClickInside ) {
            siteNavigation.classList.remove( 'toggled' );
            siteBody.classList.remove( activeClass );
            button.setAttribute( 'aria-expanded', 'false' );
        }
    } );

    // Get all the link elements within the menu.
    const links = menu.getElementsByTagName( 'a' );

    // Get all the link elements with children within the menu.
    const linksWithChildren = menu.querySelectorAll( '.menu-item-has-children > a, .page_item_has_children > a' );

    // Toggle focus each time a menu link is focused or blurred.
    for ( const link of links ) {
        link.addEventListener( 'focus', toggleFocus, true );
        link.addEventListener( 'blur', toggleFocus, true );
        link.addEventListener( 'click', toggleFocus, true );
    }

    // Toggle focus each time a menu link with children receive a touch event.
    for ( const link of linksWithChildren ) {
        var subButton = document.createElement("button");
        subButton.setAttribute('class', 'sub');
        link.parentNode.appendChild(subButton);
        subButton.addEventListener( 'touchstart', toggleFocus, { capture: false, once: false, passive: false } );
        subButton.addEventListener( 'click', toggleFocus, { capture: false, once: false, passive: false } );
    }

    function headerSubmenu() {
        const breakpoint = 1120;
        let counter = 0;

        function initialize() {
            var win = jQuery(this);
            if (win.width() < breakpoint) {
            if (counter === 1) {
                submenuInit();
            }
            counter++;
            } else {
            const submenus = jQuery('.header-nav-submenu');
            submenus.attr('style','');
            counter = 0;
            }
        }

        if (window.innerWidth < breakpoint) {
            submenuInit();
            counter = 1;
        }

        function submenuInit() {
            const navigation = document.querySelector('.header-nav');
            const navItems = navigation.querySelectorAll('.header-nav__item')
            
            navItems.forEach(function(item, index) {
                const isHasSubmenu = item.querySelector('.header-nav-submenu');
                if (!isHasSubmenu) return
                let counterClick = 0;

                item.addEventListener('click', function(e) {
                    if (counterClick === 0) {
                    e.preventDefault();
                        jQuery(isHasSubmenu).slideToggle();
                    }
                    counterClick++;
                })
            })
        }

        jQuery(window).on('resize', debounce(initialize, 100));
    }

    headerSubmenu();

    function debounce(f, ms) {
        let isCooldown = false;

        return function() {
            if (isCooldown) return;

            f.apply(this, arguments);

            isCooldown = true;

            setTimeout(() => isCooldown = false, ms);
        };
    }

    /**
     * Sets or removes .focus class on an element.
     */
    function toggleFocus() {
        // console.log('toggleFocus');
        if ( event.type === 'focus' || event.type === 'blur' || event.type === 'click') {
            let self = this;
            // Move up through the ancestors of the current link until we hit .nav-menu.
            while ( ! self.classList.contains( 'nav-menu' ) ) {
                // On li elements toggle the class .focus.
                if ( 'li' === self.tagName.toLowerCase() ) {
                    self.classList.toggle( 'focus' );
                }
                self = self.parentNode;
            }
        }

        if ( event.type === 'touchstart' ) {
            const menuItem = this.parentNode;

            event.preventDefault();
            for ( const link of menuItem.parentNode.children ) {
                if ( menuItem !== link ) {
                    link.classList.remove( 'focus' );
                }
            }
            menuItem.classList.toggle( 'focus' );
        }
    }
}() );